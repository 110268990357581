import { DataFieldBlankValue, PhoenixBaseCard, PhoenixBaseTable } from "componix"
import { CoverageGroupProfileEmployers, CoverageGroupProfileLocations, CoverageGroupProfileResponse } from "../../models/CoverageGroupProfileResponse"
import { MRT_ColumnDef } from "material-react-table"
import { useGetCoverageGroupProfile } from "../../cache/coverageCache"
import useSetAlertDetails from "../../hooks/workQueue/useSetAlertDetails"
import { handleTitleCase } from "../../utils/handleTitleCase"


const EmployersColumns: MRT_ColumnDef<CoverageGroupProfileEmployers>[] = [
    {
        accessorKey: 'insuredName',
        header: 'Insured Name',
        Cell: ({cell}) => cell.getValue() ? handleTitleCase(cell.getValue<string>()): <DataFieldBlankValue/>
    },
    {
        accessorKey: 'effectiveDate',
        header: 'Effective Date'
    },
    {
        accessorKey: 'expirationDate',
        header: 'Expiration Date'
    }   
]
const LocationsColumns: MRT_ColumnDef<CoverageGroupProfileLocations>[] = [
    {
        accessorKey: 'street',
        header: 'Street',
        Cell: ({cell}) => cell.getValue() ? handleTitleCase(cell.getValue<string>()): <DataFieldBlankValue/>,
        size: 250
    },
    {
        accessorKey: 'city',
        header: 'City',
        Cell: ({cell}) => cell.getValue() ? handleTitleCase(cell.getValue<string>()): <DataFieldBlankValue/>,
    },
    {
        accessorKey: 'state',
        header: 'State',
        maxSize: 70
    },
    {
        accessorKey: 'zipCode',
        header: 'Zip Code',
        maxSize: 70
    },
    {
        accessorKey: 'firstPolicyEffectiveDate',
        header: 'First Policy Effective Date',
        maxSize: 125
    },
    {
        accessorKey:'lastPolicyExpirationDate',
        header: 'Last Policy Expiration Date',
        maxSize: 125
    }   
]

interface CoverageGroupProfileProps{
    coverageId: string
}
export const CoverageGroupProfile = ({coverageId}: CoverageGroupProfileProps)=>{
    const {
        lazyFetch,
        data,
        isLoading,
        isError,
    } =useGetCoverageGroupProfile(coverageId)
    useSetAlertDetails([isError])
    return(
        <PhoenixBaseCard cardTitle="Coverage Group Profile" collapsible contentPadded onExpand={lazyFetch} isLoading={isLoading}>
            <PhoenixBaseCard cardTitle="Employers" variantType="Secondary">
                <PhoenixBaseTable 
                columns={EmployersColumns} 
                data={data?.employers ?? []} />
            </PhoenixBaseCard>
            <PhoenixBaseCard cardTitle="Locations" variantType="Secondary">
                <PhoenixBaseTable 
                    columns={LocationsColumns} 
                    data={data?.locations ?? []}  
                />
            </PhoenixBaseCard>
        </PhoenixBaseCard>
    )
}